<template>
  <v-dialog v-model="showDialog" persistent max-width="400">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ editIncendio.LOCALIDAD }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form v-model="isValid">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field v-model="editIncendio.NOMBRE" label="Nombre" placeholder="Nombre" prepend-icon="mdi-tag" hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field v-model="editIncendio.MUNICIPIO" label="Municipio" placeholder="Municipio" prepend-icon="mdi-terrain" hide-details disabled />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field v-model="editIncendio.LOCALIDAD" label="Localidad" placeholder="Localidad" prepend-icon="mdi-terrain" hide-details disabled />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col sm="6">
                <v-text-field v-model="editIncendio.LATITUD" label="Latitud" dense outlined prepend-icon="mdi-map-marker" @blur="updatedLatLon()" v-mask="'##.#####'" hide-details />
              </v-col>

              <v-col sm="5">
                <v-text-field v-model="editIncendio.LONGITUD" label="Longitud" dense outlined @blur="updatedLatLon()" v-mask="'-#.#####'" hide-details /> <!-- TODO: en alguna ocasion el - no sera obligatorio -->
              </v-col>

              <v-col sm="1">
                <v-btn icon color="blue" @click="editLocation">
                  <v-icon>mdi-map-marker-star</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col sm="6">
                <v-text-field v-model="editIncendio.xETRS89" label="X" dense outlined prepend-icon="mdi-map-marker" @blur="updatedXY()" v-mask="'######'" hide-details />
              </v-col>

              <v-col sm="5">
                <v-text-field v-model="editIncendio.yETRS89" label="Y" dense outlined @blur="updatedXY()" v-mask="'#######'" hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select v-model="editIncendio.ID_TIPO_INCENDIO" :items="tiposIncendio" label="Tipo" dense outlined prepend-icon="mdi-fire" :item-text="(tipo) => {return tipo.TIPO_INCENDIO}" item-value="ID_TIPO_INCENDIO" placeholder="Tipo incendio" single-line autocomplete hide-details :disabled="disabledTipoIncendio" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select v-model="editIncendio.ID_TIPO_DETECCION" :items="tiposDeteccion" label="Detección" dense outlined prepend-icon="esri-icon-hollow-eye" item-text="DETECCION" item-value="ID_TIPO_DETECCION" placeholder="Detección" single-line autocomplete hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea v-model="editIncendio.OBSERVACIONES" label="Observaciones" dense outlined placeholder="Observaciones" prepend-icon="esri-icon-comment" auto-grow hide-details :rules="[v => (v || '' ).length <= 100 || 'Número de caracteres maximo: 100']" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green darken-1" @click="aceptar" :disabled="!isValid">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as ArcGIS from '../../../helpers/ArcGIS'

export default {
  props: {
    show: Boolean,
    incendio: Object
  },

  data: () => ({
    showDialog: false,
    isValid: true,
    editIncendio: {},
    isShowNewCoordenadas: false
  }),

  computed: {
    ...mapGetters('incendio', [
      'tiposDeteccion', 'tiposIncendio'
    ]),

    ...mapGetters('map', [
      'gettingNewCoordenadas',
      'newCoordenadas'
    ]),

    disabledTipoIncendio () {
      if (this.incendio && this.incendio.ESTADOS) {
        return this.incendio.ESTADOS.some(estado => {
          return (this.incendio.ID_TIPO_INCENDIO === this.tiposIncendio[0].ID_TIPO_INCENDIO && estado.TIPO_INCENDIO_ESTADO.VALUE === 2) ||
          (this.incendio.ID_TIPO_INCENDIO === this.tiposIncendio[1].ID_TIPO_INCENDIO && estado.TIPO_INCENDIO_ESTADO.VALUE === 4)
        })
      }
      return false
    }
  },

  watch: {
    show () {
      this.showDialog = this.show

      if (this.show) {
        this.editIncendio = JSON.parse(JSON.stringify(this.incendio))
        let coor3857 = ArcGIS.convertirLatLonToETRS89(this.editIncendio.LATITUD, this.editIncendio.LONGITUD)
        this.editIncendio.xETRS89 = coor3857[0].toFixed(2)
        this.editIncendio.yETRS89 = coor3857[1].toFixed(2)
      }
    },

    gettingNewCoordenadas () {
      if (!this.gettingNewCoordenadas && this.isShowNewCoordenadas && this.show) { // Volver a mostrar el dialogo tras capturar el punto
        this.showDialog = true
      }
    },

    newCoordenadas () {
      if (this.isShowNewCoordenadas) {
        this.editIncendio.MUNICIPIO = this.newCoordenadas.MUNICIPIO
        this.editIncendio.LOCALIDAD = this.newCoordenadas.LOCALIDAD

        this.editIncendio.LATITUD = this.newCoordenadas.LATITUD
        this.editIncendio.LONGITUD = this.newCoordenadas.LONGITUD

        this.editIncendio.xETRS89 = this.newCoordenadas.X.toFixed(2)
        this.editIncendio.yETRS89 = this.newCoordenadas.Y.toFixed(2)

        this.paintIncendio()
        this.showDialog = true
        this.isShowNewCoordenadas = false
      }
    }
  },

  methods: {
    ...mapActions('map', [
      'startNewCoordenadas'
    ]),

    clearIncendio () {
      ArcGIS.paintPositionIncendio(null)
    },
    paintIncendio () {
      // Por aqui no pasa
      ArcGIS.paintPositionIncendio({ LATITUD: this.editIncendio.LATITUD, LONGITUD: this.editIncendio.LONGITUD })
    },

    editLocation () {
      this.showDialog = false
      this.isShowNewCoordenadas = true
      this.startNewCoordenadas()
    },

    updatedLatLon () {
      if (this.latitud && this.longitud && this.xETRS89 && this.yETRS89) {
        let xy = ArcGIS.convertirLatLonToETRS89(this.latitud, this.longitud)
        this.xETRS89 = xy[0].toFixed(2)
        this.yETRS89 = xy[1].toFixed(2)

        this.paintIncendio()
      }
    },

    updatedXY () {
      if (this.latitud && this.longitud && this.xETRS89 && this.yETRS89) {
        let latLon = ArcGIS.convertirETRS89ToLatLon(this.xETRS89, this.yETRS89)
        this.longitud = latLon[0].toFixed(5)
        this.latitud = latLon[1].toFixed(5)

        this.paintIncendio()
      }
    },

    cancelar () {
      this.clearIncendio()
      this.$emit('cancelar')
    },
    aceptar () {
      this.clearIncendio()
      this.$emit('aceptar', this.editIncendio)
    }
  }
}
</script>

<style scoped>
</style>
