import request from '../request'

export default {
  getImagenesSatelite: function (data) {
    return request.get(`satelite_imagen/${data.ID_INCENDIO}`)
  },
  deleteImagenIncendio: function (data) {
    return request.delete('incendio_imagen/' + data.ID_INCENDIO + '/' + data.ID_INCENDIO_IMAGEN)
  },
  getImagenesIncendio: function (data) {
    return request.get('incendio_imagen/' + data)
  },
  setImagenesIncendio: function (data) {
    return request.post('incendio_imagen/' + data.ID_INCENDIO, data.imagenes)
  }
}
