<template>
  <div>
    <v-snackbar top :timeout="3000" color="error" v-model="showSnackBarError">
      Posición fuera del ambito de actuación.
    </v-snackbar>

    <v-fade-transition>
      <div id="contextMenu" v-show="showContextMenu">
        <v-list class="pa-0" color="secondary" dense>
          <v-list-item class="contextOption" v-for="item in buttons" v-show="item.show" :key="item.action" @click="clickBtn(item.action)">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.label" />
          </v-list-item>
        </v-list>
      </div>
    </v-fade-transition>
    <v-card>
      <vx-dialog-copiar-coordenadas
        :show="showDialog"
        @cerrar="showDialog = false"
      />
    </v-card>
  </div>
</template>

<script>
import * as ArcGIS from '../../helpers/ArcGIS'
import * as MunicipioAPI from '../../helpers/MunicipioAPI'
import * as PermisosHelper from '../../helpers/PermisosHelper'
import DialogCopiarCoordenadasVue from './DialogCopiarCoordenadas.vue'

export default {
  components: {
    'vx-dialog-copiar-coordenadas': DialogCopiarCoordenadasVue
  },

  props: {
    showIncendiosCards: Boolean
  },
  data: () => ({
    showContextMenu: false,
    buttons: [
      { label: 'Nuevo incendio', icon: 'mdi-plus', action: 'addIncendio', show: true },
      { label: 'Zoom', icon: 'mdi-magnify', action: 'zoom', show: true },
      { label: 'Google Maps', icon: 'mdi-google-maps', action: 'openGoogleMaps', show: true },
      { label: 'Copiar coordenadas', icon: 'mdi-content-copy', action: 'copyCoordinates', show: true }
    ],
    showDialog: false,

    showSnackBarError: false,

    clickPoint: null
  }),

  computed: {
    mapsRef () {
      return 'https://www.google.com/maps?q=' + this.clickPoint.attributes.LATITUDE + ',' + this.clickPoint.attributes.LONGITUDE
    }
  },

  methods: {
    contextMenu (data) {
      if (!data) {
        this.showContextMenu = false
      } else {
        this.clickPoint = data
        this.updatePosition()
        this.showContextMenu = true
      }
    },

    updatePosition () {
      let positionX = this.clickPoint.position.x
      let positionY = this.clickPoint.position.y

      let menu = document.getElementById('contextMenu')
      menu.style.top = positionY + 'px'
      menu.style.left = positionX + 'px'
    },

    clickBtn (action) {
      switch (action) {
        case 'addIncendio':
          this.addIncendio()
          break
        case 'zoom':
          this.zoom()
          break
        case 'openGoogleMaps':
          this.openGoogleMaps()
          break
        case 'copyCoordinates':
          this.showCopiarCoordenadasDialog()
          break
      }

      this.showContextMenu = false
    },

    async addIncendio () {
      let pointData = await MunicipioAPI.getPointData(this.clickPoint.attributes.X, this.clickPoint.attributes.Y)

      // Comprobar si esta en buffer
      this.$emit('showAddIncendio', pointData)
    },

    zoom () {
      let center = {
        target: [this.clickPoint.attributes.LONGITUDE, this.clickPoint.attributes.LATITUDE],
        zoom: 15
      }
      ArcGIS.setCenterMap(center)
    },

    openGoogleMaps () {
      navigator.clipboard.writeText(this.mapsRef)
      window.open(this.mapsRef, '_blank')
    },

    showCopiarCoordenadasDialog () {
      this.showDialog = !this.showDialog
    },
    isExterno () {
      return PermisosHelper.isExterno()
    }

  },

  mounted () {
    this.buttons.find(x => x.action === 'addIncendio').show = !this.isExterno()
    this.$eventHub.$on('showContextMenu', this.contextMenu)
  }
}
</script>

<style scoped>
  #contextMenu {
    position: absolute !important;
    max-width: 400px;
    background-color: white;
  }
  .contextOption {
    transition: 0.3s;
  }
  .contextOption:hover {
    background-color: #00749e;
    color: white;
  }
</style>
