<template>
  <v-app-bar color="primary" dark app height="64px">
    <!--  clipped-left fixed  -->
    <div class="d-flex align-center mr-6">
      <img height="45" src="../../assets/llama.svg" alt="Logo">
    </div>

    <v-toolbar-title class="hidden-md-and-down">
      Incendios - {{ paramsComunidad ? paramsComunidad.NOMBRE : '' }} ({{ currentUsuario ? currentUsuario.USUARIO : '' }})
    </v-toolbar-title>

    <v-spacer />

    <!-- Botones capas -->
    <div class="d-flex" v-if="isMap">
      <v-tooltip bottom v-for="item in layerButtons" :key="item.name">
        <template #activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <vx-toggle-layer-button :icon="item.icon" :layerId="item.layerId" v-show="!isExterno()" />
          </div>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>

      <v-divider class="mx-3" vertical />
    </div>

    <!-- Toolbar escritorio -->
    <div class="d-flex" v-if="!$vuetify.breakpoint.mobile">
      <!-- Botones rutas -->
      <v-tooltip bottom v-for="item in getPathButtons" :key="item.name">
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" :to="item.path" v-show="!item.disabled">
            <v-icon>{{ item.icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>

      <v-divider class="mx-3" vertical />

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-show="!$vuetify.breakpoint.mobile && !isExterno()" icon v-bind="attrs" v-on="on" @click="showListadoIncendiosDialog = true">
            <v-icon>mdi-playlist-edit</v-icon>
          </v-btn>
        </template>
        <span>Listado de incendios</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-show="!$vuetify.breakpoint.mobile&& !isExterno()" icon v-bind="attrs" v-on="on" @click="showSettingsDialog = true">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <span>Ajustes</span>
      </v-tooltip>

      <v-divider class="mx-3" v-show="!$vuetify.breakpoint.mobile&& !isExterno()" vertical />

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="initLogout">
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </div>

    <!-- Toolbar movil -->
    <div class="d-flex" v-if="$vuetify.breakpoint.mobile">
      <v-dialog v-model="showMobileMenu" fullscreen hide-overlay transition="dialog-bottom-transition">
        <template #activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on" />
        </template>

        <v-card tile>
          <v-toolbar flat color="primaryLight" dark>
            <v-toolbar-title>Menú</v-toolbar-title>

            <v-spacer />
            <v-btn icon @click="showMobileMenu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-list>
            <v-list-item v-for="(item, i) in getPathButtons" :key="i" :to="item.path" @click="showMobileMenu = false" v-show="!item.disabled">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title :title="item.name">{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item @click="initLogout">
              <v-list-item-action>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </div>

    <vx-listado-incendios-dialog :show="showListadoIncendiosDialog" @cerrar="showListadoIncendiosDialog = false" />
    <vx-settings-dialog :show="showSettingsDialog" @cerrar="showSettingsDialog = false" />
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ListadoIncendiosDialog from '../shared/ListadoIncendiosDialog'
import SettingsDialog from '../shared/SettingsDialog'
import ToggleLayerButton from '../shared/ToggleLayerButton'
import * as PermisosHelper from '../../helpers/PermisosHelper'

export default {
  components: {
    'vx-toggle-layer-button': ToggleLayerButton,

    'vx-listado-incendios-dialog': ListadoIncendiosDialog,
    'vx-settings-dialog': SettingsDialog
  },

  data: () => ({
    layerButtons: [
      { layerId: 'layer-incendios', icon: 'mdi-fire', name: 'Incendios' },
      { layerId: 'layer-medios', icon: 'mdi-account-group', name: 'Medios activos' },
      { layerId: 'layer-medios-inactivos', icon: 'mdi-account-group-outline', name: 'Medios inactivos' },
      { layerId: 'layer-etiquetas', icon: 'mdi-tag', name: 'Etiquetas' }
    ],

    pathButtons: [
      { path: '/', icon: 'mdi-terrain', name: 'Mapa' },
      { path: '/actuaciones', icon: 'mdi-calendar', name: 'Actuaciones', disabled: false },
      { path: '/medios', icon: 'mdi-transit-transfer', name: 'Gestión de medios', disabled: false },
      { path: '/planificacion_mmaa', icon: 'mdi-airplane', name: 'Planificación MMAA', disabled: false },
      { path: '/planificacion_mmtt', icon: 'mdi-fire-truck', name: 'Planificación MMTT', disabled: false }
    ],

    showMobileMenu: false,

    showListadoIncendiosDialog: false,
    showSettingsDialog: false
  }),

  computed: {
    ...mapGetters('map', [
      'paramsComunidad'
    ]),

    ...mapGetters('usuario', [
      'currentUsuario'
    ]),

    isMap () {
      return this.$route.path === '/'
    },

    isMobile () {
      return this.$route.path === '/'
    },

    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    },
    getPathButtons () {
      let newPathButtons = this.pathButtons
      if (this.disabledByRol || this.isExterno()) {
        newPathButtons.forEach(elem => {
          if (elem.disabled !== undefined && !elem.disabled) {
            elem.disabled = true
          }
        })
      }
      return newPathButtons
    }
  },

  methods: {
    ...mapActions('auth', [
      'logout'
    ]),

    initLogout () {
      this.$root.$confirmDialog.open('Cerrar sesión', '¿Está seguro de que quiere salir?').then(result => {
        if (result) {
          this.logout()
        }
      })
    },
    isExterno () {
      return PermisosHelper.isExterno()
    }
  }
}
</script>

<style scoped>
</style>
