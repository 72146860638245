<template>
  <div class="fill-height">
    <vx-left-menu :show="showLeftMenu" :idIncendioSelected="idIncendioSelected" @exit="hideLeftMenu" />

    <!-- <v-content fill-height> -->
    <vx-map :showIncendiosCards="showIncendiosCards" @selectIncendio="selectIncendio" />
    <!-- </v-content> -->
  </div>
</template>

<script>
import Map from './Map'
import LeftMenu from './leftmenu/LeftMenu'

export default {
  name: 'Map',

  components: {
    'vx-left-menu': LeftMenu,
    'vx-map': Map
  },

  data: () => ({
    showLeftMenu: false,

    idIncendioSelected: null,
    showIncendiosCards: true,
    drawer: true,
    incendio: {}
  }),

  methods: {
    selectIncendio (idIncendio) {
      this.showLeftMenu = true
      this.idIncendioSelected = idIncendio
      this.showIncendiosCards = false
    },

    hideLeftMenu () {
      this.showLeftMenu = false
      this.idIncendioSelected = null
      this.showIncendiosCards = true
    }
  }
}
</script>

<style scoped>
</style>
