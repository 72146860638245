<template>
  <v-dialog v-model="show" persistent max-width="650px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-form v-model="isValid">
            <v-row>
              <!-- Datos medio -->
              <v-col sm="4">
                <v-select v-model="medioSelected" :items="mediosOrder" v-if="!disableMedio" label="Medio" item-text="MEDIO" item-value="MEDIO" outlined dense return-object autocomplete :rules="[globalRules.required]" />
                <v-text-field v-model="medioSelected.MEDIO" label="Medio" v-if="disableMedio" outlined dense readonly />
                <v-text-field v-model="medioSelected.TIPO" label="Tipo" outlined dense readonly />
                <v-text-field v-model="numComponentes" label="Nº componentes" type="number" outlined dense :min="1" />

                <vx-date-text-edit v-model="fechaIncorporacion" label="Fecha incorporación" outlined dense onlyDate required />

                <!-- <VueCtkDateTimePicker id="fechaIncorporacionDatePicker"
                                label="Fecha incorporación"
                                color="#00759e"
                                format="YYYY-MM-DD"
                                formatted="ll"
                                onlyDate
                                overlay
                                noClearButton
                                position="top"
                                :error="this.errorDate"
                                v-on:is-shown="abrirDatePicker('fechaIncorporacionDatePicker')"
                                v-on:is-hidden="cerrarDatePicker('fechaIncorporacionDatePicker')"
                                button-now-translation="Hoy"
                                v-model="fechaIncorporacion"
                                ref="fechaIncorporacionDatePicker">
                </VueCtkDateTimePicker> -->
              </v-col>

              <!-- Primera incorporacion -->
              <v-col>
                <v-card class="elevation-5">
                  <v-toolbar color="green" dense>
                    <v-toolbar-title>Primera incorporación</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row dense>
                        <v-col>
                          <vx-date-text-edit v-model="inicioJornada" label="Inicio jornada" outlined dense required />
                          <!-- <VueCtkDateTimePicker id="inicioJornadaDatePicker"
                                    label="Inicio jornada"
                                    color="#00759e"
                                    format="YYYY-MM-DD HH:mm"
                                    noClearButton
                                    overlay
                                    position="bottom"
                                    :error="this.errorDate"
                                    v-on:is-shown="abrirDatePicker('inicioJornadaDatePicker')"
                                    v-on:is-hidden="cerrarDatePicker('inicioJornadaDatePicker')"
                                    button-now-translation="Ahora"
                                    v-model="inicioJornada"
                                    ref="inicioJornadaDatePicker">
                    </VueCtkDateTimePicker> -->
                        </v-col>

                        <v-col>
                          <v-select v-model="sectorSelected" :items="sectores" label="Sector" outlined dense item-text="SECTOR" item-value="ID_SECTOR" return-object autocomplete />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col>
                          <vx-time-text-edit v-model="horaMovilizacion" label="Hora movilización" outlined dense required />
                          <!-- <VueCtkDateTimePicker id="horaMovilizacionDatePicker"
                                    label="Hora movilización"
                                    color="#00759e"
                                    format="HH:mm"
                                    formatted="HH:mm "
                                    onlyTime
                                    overlay
                                    noClearButton
                                    position="bottom"
                                    :error="this.errorDate"
                                    v-on:is-shown="abrirDatePicker('horaMovilizacionDatePicker')"
                                    v-on:is-hidden="cerrarDatePicker('horaMovilizacionDatePicker')"
                                    button-now-translation="Ahora"
                                    v-model="horaMovilizacion"
                                    ref="horaMovilizacionDatePicker">
                    </VueCtkDateTimePicker> -->
                        </v-col>

                        <v-col>
                          <vx-time-text-edit v-model="horaLlegadaInc" label="Llegada a incendio" outlined dense required />
                          <!-- <VueCtkDateTimePicker id="llegadaIncDatePicker"
                                    label="Llegada a incendio"
                                    color="#00759e"
                                    format="HH:mm"
                                    formatted="HH:mm"
                                    onlyTime
                                    overlay
                                    noClearButton
                                    position="top"
                                    :error="this.errorDate"
                                    v-on:is-shown="abrirDatePicker('llegadaIncDatePicker')"
                                    v-on:is-hidden="cerrarDatePicker('llegadaIncDatePicker')"
                                    button-now-translation="Ahora"
                                    v-model="horaLlegadaInc"
                                    ref="llegadaIncDatePicker">
                        </VueCtkDateTimePicker> -->
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col>
                          <v-text-field v-model="horasOperativo" label="Tpo. operativo (horas)" type="number" outlined dense hide-details />
                        </v-col>

                        <v-col>
                          <v-text-field v-model="horasDescanso" label="Tpo. descanso (horas)" type="number" outlined dense hide-details />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green darken-1" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'

export default {
  components: {
    // VueCtkDateTimePicker: VueCtkDateTimePicker
  },

  props: {
    show: Boolean,

    isEdit: Boolean,
    editedItem: {
      type: Object,
      default: () => { return {} }
    },

    idIncendioSelected: String,
    medios: Array,
    sectores: Array
  },

  data: () => ({
    titulo: 'Añadir medio',
    isValid: false,

    mediosOrder: [],

    // Info medio
    medioSelected: {},
    numComponentes: 1,
    fechaIncorporacion: null,

    horasOperativo: 17,
    horasDescanso: 12,

    // Primera incorporacion
    inicioJornada: null,
    horaMovilizacion: null,
    horaLlegadaInc: null,
    sectorSelected: null,

    // Edit
    disableMedio: false
  }),

  computed: {
  },

  watch: {
    show () {
      if (this.show) {
        if (this.isEdit) {
          // Bloquear selector medio
          this.disableMedio = true
          this.titulo = 'Editar medio'

          this.medioSelected = {}
          this.medioSelected.ID_MEDIO = this.editedItem.ID_MEDIO
          this.medioSelected.MEDIO = this.editedItem.MEDIO
          this.medioSelected.TIPO = this.editedItem.TIPO
          this.medioSelected.HORA_ENTRADA = this.$date.parseDate(this.editedItem.INICIO_JORNADA).format('HH:mm')

          this.inicioJornada = this.$date.parseDate(this.editedItem.INICIO_JORNADA).format('DD/MM/YYYY HH:mm')
          this.horasOperativo = this.editedItem.TIEMPO_OPERATIVO
          this.horasDescanso = this.editedItem.TIEMPO_DESCANSO

          this.fechaIncorporacion = this.$date.parseDate(this.editedItem.FECHA_INCORPORACION, 'DD/MM/YYYY').format('DD/MM/YYYY') // moment(this.editedItem.FECHA_INCORPORACION, 'DD-MM-YYYY').format('YYYY-MM-DD')
          this.horaMovilizacion = this.$date.formatTime(this.editedItem.HORA_MOVILIZACION) // moment(this.editedItem.HORA_MOVILIZACION, 'HH:mm').format('HH:mm')
          this.horaLlegadaInc = this.editedItem.HORA_LLEGADA_INC ? this.$date.formatTime(this.editedItem.HORA_LLEGADA_INC) : '' // moment(this.editedItem.HORA_LLEGADA_INC, 'HH:mm').format('HH:mm')
          this.numComponentes = this.editedItem.NUM_COMPONENTES
          this.sectorSelected = this.editedItem.SECTOR
        } else {
          this.disableMedio = false
          this.titulo = 'Añadir medio'

          this.medioSelected = this.mediosOrder[0]
          this.horasOperativo = parseInt(this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_ACTUACION'))
          this.horasDescanso = parseInt(this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_DESCANSO'))

          this.fechaIncorporacion = this.$date.now().format('DD/MM/YYYY') // this.$date.currentTime() // moment().locale('es').format('YYYY-MM-DD')
          this.horaMovilizacion = this.$date.currentTime() // moment().locale('es').format('HH:mm')
          this.horaLlegadaInc = this.$date.now().add(15, 'minutes').format('HH:mm') // moment().locale('es').add(15, 'minutes').format('HH:mm')
          this.inicioJornada = this.$date.parseDate(this.fechaIncorporacion + ' ' + this.medioSelected.HORA_ENTRADA).format('DD/MM/YYYY HH:mm') // moment(this.fechaIncorporacion + ' ' + this.medioSelected.HORA_ENTRADA).locale('es').format('YYYY-MM-DD HH:mm')
          this.numComponentes = 1
        }
      }
    },

    medios () {
      this.orderMedios()
    },

    sectores () {
      this.sectorSelected = this.sectores[0]
    },

    medioSelected () {
      if (!this.isEdit) {
        this.inicioJornada = this.$date.parseDate(this.fechaIncorporacion + ' ' + this.medioSelected.HORA_ENTRADA).format('DD/MM/YYYY HH:mm') // moment(this.fechaIncorporacion + ' ' + this.medioSelected.HORA_ENTRADA).locale('es').format('YYYY-MM-DD HH:mm')
      }
    }
  },

  methods: {
    orderMedios () {
      let mediosOrder = JSON.parse(JSON.stringify(this.medios))

      for (let i = 0; i < mediosOrder.length; i++) {
        let med = mediosOrder[i]
        if (med.ESTADO === 3) {
          med.ESTADO = 0
        }
      }

      mediosOrder.sort((a, b) => {
        let aEstado = a.ESTADO
        let bEstado = b.ESTADO
        let aMedio = a.MEDIO.toUpperCase()
        let bMedio = b.MEDIO.toUpperCase()

        if (aEstado === bEstado) {
          return (aMedio < bMedio) ? -1 : (aMedio > bMedio) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })
      // this.$log.debug('order', mediosOrder)
      this.mediosOrder = mediosOrder
      this.mediosOrder = this.mediosOrder.filter(x => !x.FECHA_FIN_DESCANSO || this.$date.parseDate(x.FECHA_FIN_DESCANSO) < this.$date.now())
    },

    cancelar () {
      this.$emit('cancelar')
    },

    aceptar () {
      let data = {}

      let horaMovilizacion = this.$date.parseDate(this.fechaIncorporacion + ' ' + this.horaMovilizacion) // moment(this.fechaIncorporacion + ' ' + this.horaMovilizacion) // Al principio, ambas horas tienen la misma fecha, luego se actualiza
      let inicioJornada = this.$date.parseDate(this.inicioJornada)

      if (this.horaLlegadaInc) {
        let horaLlegadaInc = this.$date.parseDate(this.fechaIncorporacion + ' ' + this.horaLlegadaInc) // moment(this.fechaIncorporacion + ' ' + this.horaLlegadaInc)
        let tiempoOperativo = this.horasOperativo

        let fechaMaximaActuacion = this.$date.parseDate(this.inicioJornada).add(tiempoOperativo, 'hours')

        // Tener en cuenta el itinere
        let itinere = horaLlegadaInc.diff(horaMovilizacion, 'minutes')
        let fechaSalidaInc = this.$date.parseDate(fechaMaximaActuacion).subtract(itinere, 'minutes')

        // Si la hora de llegada al incendio es posterior, es del dia siguiente
        if (horaLlegadaInc < horaMovilizacion) {
          horaLlegadaInc = horaLlegadaInc.add(1, 'days')
        }

        // Fecha llegada base
        // let fechaLlegadaBase = moment(fechaMaximaActuacion).add(horaLlegadaInc.diff(horaMovilizacion, 'minutes'), 'minutes')
        let fechaLlegadaBase = this.$date.parseDate(fechaMaximaActuacion)

        // Fecha fin descanso (siguiente reincorporacion)
        let fechaFinDescanso = this.$date.parseDate(fechaLlegadaBase).add(this.horasDescanso, 'hours') // 12
        data = {
          ID_MEDIO: this.medioSelected.ID_MEDIO, // Para desplegar la tabla requiere tener un campo 'id'
          MEDIO: this.medioSelected.MEDIO,
          ACTUACION: 1,
          TIPO: this.medioSelected.TIPO,
          SECTOR: this.sectorSelected,
          NUM_COMPONENTES: this.numComponentes === '' ? '1' : this.numComponentes,
          INICIO_JORNADA: inicioJornada, // this.inicioJornada,
          TIEMPO_OPERATIVO: this.horasOperativo,
          TIEMPO_DESCANSO: this.horasDescanso,
          FECHA_INCORPORACION: this.fechaIncorporacion,
          HORA_MOVILIZACION: horaMovilizacion,
          HORA_LLEGADA_INC: horaLlegadaInc,
          FECHA_SALIDA_INC: fechaSalidaInc,
          FECHA_MAX_ACTUACION: fechaMaximaActuacion,
          FECHA_LLEGADA_BASE: fechaLlegadaBase,
          FECHA_FIN_DESCANSO: fechaFinDescanso
        }
      } else {
        data = {
          ID_MEDIO: this.medioSelected.ID_MEDIO, // Para desplegar la tabla requiere tener un campo 'id'
          MEDIO: this.medioSelected.MEDIO,
          ACTUACION: 1,
          TIPO: this.medioSelected.TIPO,
          SECTOR: this.sectorSelected,
          NUM_COMPONENTES: this.numComponentes === '' ? '1' : this.numComponentes,
          INICIO_JORNADA: inicioJornada, // this.inicioJornada,
          TIEMPO_OPERATIVO: this.horasOperativo,
          TIEMPO_DESCANSO: this.horasDescanso,
          FECHA_INCORPORACION: this.fechaIncorporacion,
          HORA_MOVILIZACION: horaMovilizacion,
          HORA_LLEGADA_INC: null,
          FECHA_SALIDA_INC: null,
          FECHA_MAX_ACTUACION: null,
          FECHA_LLEGADA_BASE: null,
          FECHA_FIN_DESCANSO: null
        }
      }

      if (!this.isEdit) { // Insertar nuevo
        this.$emit('aceptar', data)
      } else { // Editar
        this.$emit('editar', data)
      }

      /* let fechaInicioJornada = this.inicioJornada.split(' ')[0]
      let horaInicioJornada = this.inicioJornada.split(' ')[1]

      let horaMovilizacion = this.$date.parseDate(this.fechaIncorporacion + ' ' + this.horaMovilizacion) // moment(this.fechaIncorporacion + ' ' + this.horaMovilizacion) // Al principio, ambas horas tienen la misma fecha, luego se actualiza
      let horaLlegadaInc = this.$date.parseDate(this.fechaIncorporacion + ' ' + this.horaMovilizacion) // moment(this.fechaIncorporacion + ' ' + this.horaLlegadaInc)

      // Fecha maxima actuacion (salida incendio)
      let jornada1 = 12
      let jornada2 = 17
      if (this.medioSelected.TIPO === 'Retén Tragsa' || this.medioSelected.TIPO === 'Cuadrilla Helitransportada') {
        jornada1 = 8
        jornada2 = 12
      }
      let finTrabajo1 = null
      if (horaLlegadaInc < horaMovilizacion) {
        finTrabajo1 = this.$date.parseDate(this.fechaIncorporacion + ' ' + this.horaLlegadaInc).add(24 + jornada1, 'hours') // moment(this.fechaIncorporacion + ' ' + this.horaLlegadaInc).add(24 + jornada1, 'hours')
      } else {
        finTrabajo1 = this.$date.parseDate(this.fechaIncorporacion + ' ' + this.horaLlegadaInc).add(jornada1, 'hours') // moment(this.fechaIncorporacion + ' ' + this.horaLlegadaInc).add(jornada1, 'hours')
      }
      let finTrabajo2 = null
      if (horaLlegadaInc < this.$date.parseDate(this.inicioJornada)) { // moment(this.inicioJornada)
        finTrabajo2 = this.$date.parseDate(fechaInicioJornada + ' ' + horaInicioJornada).add(jornada2, 'hours') // moment(fechaInicioJornada + ' ' + horaInicioJornada).add(jornada2, 'hours')
      } else {
        finTrabajo2 = this.$date.parseDate(this.fechaIncorporacion + ' ' + horaInicioJornada).add(jornada2, 'hours') // moment(this.fechaIncorporacion + ' ' + horaInicioJornada).add(jornada2, 'hours')
      }
      let fechaMaximaActuacion = null
      if (finTrabajo1 < finTrabajo2) {
        fechaMaximaActuacion = finTrabajo1
      } else {
        fechaMaximaActuacion = finTrabajo2
      }

      // Si la hora de llegada al incendio es posterior, es del dia siguiente
      if (horaLlegadaInc < horaMovilizacion) {
        horaLlegadaInc.add(1, 'days')
      }

      // Fecha llegada base
      let fechaLlegadaBase = this.$date.parseDate(fechaMaximaActuacion).add(horaLlegadaInc.diff(horaMovilizacion, 'minutes'), 'minutes') // moment(fechaMaximaActuacion).add(horaLlegadaInc.diff(horaMovilizacion, 'minutes'), 'minutes')

      // Fecha fin descanso (siguiente reincorporacion)
      let fechaFinDescanso = this.$date.parseDate(fechaLlegadaBase).add(12, 'hours') // moment(fechaLlegadaBase).add(12, 'hours')

      let data = {
        ID_MEDIO: this.medioSelected.ID_MEDIO, // Para desplegar la tabla requiere tener un campo 'id'
        MEDIO: this.medioSelected.MEDIO,
        ACTUACION: 1,
        TIPO: this.medioSelected.TIPO,
        SECTOR: this.sectorSelected,
        NUM_COMPONENTES: this.numComponentes === '' ? '1' : this.numComponentes,
        INICIO_JORNADA: this.inicioJornada,
        FECHA_INCORPORACION: this.fechaIncorporacion,
        HORA_MOVILIZACION: horaMovilizacion,
        HORA_LLEGADA_INC: horaLlegadaInc,
        FECHA_MAX_ACTUACION: fechaMaximaActuacion,
        FECHA_LLEGADA_BASE: fechaLlegadaBase,
        FECHA_FIN_DESCANSO: fechaFinDescanso
      }

      if (!this.isEdit) { // Insertar nuevo
        this.$emit('aceptar', data)
      } else { // Editar
        this.$emit('editar', data)
      } */
    }
  },

  mounted () {
  }
}
</script>

<style scoped>
</style>
