import api from '@/api'
import constants from '../../helpers/constants'
import Vue from '../../main'

let Q = require('q')

const state = {
  simulaciones: []
}

const getters = {
  simulaciones: (state) => state.simulaciones
}

const mutations = {
  SET_SIMULACIONES (state, simulaciones) {
    state.simulaciones = simulaciones
  },

  ADD_SIMULACION (state, simulacion) {
    state.simulaciones.push(simulacion)
  },

  DELETE_SIMULACION (state, simulacion) {
    state.simulaciones.splice(state.simulaciones.indexOf((a) => a.ID_SIMULACION === simulacion.ID_SIMULACION))
  }
}

const actions = {
  async fetchSimulaciones ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.simulacion.getSimulaciones(data)
      commit('SET_SIMULACIONES', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async addSimulacion ({ commit }, data) {
    let deferred = Q.defer()

    let datosSimulador = data.datosSimulador
    let datosBackend = data.datosBackend
    try {
      const responseSimulador = await api.simulacion.simulate(datosSimulador) // Crear simulacion en server simulacion

      if (responseSimulador.status === 200) {
        datosBackend.ID_SIMULACION = responseSimulador.data
        const responseBackend = await api.simulacion.addSimulacion(datosBackend) // Insertar simulacion en BBDD backend

        if (responseBackend.status === 200) {
          commit('ADD_SIMULACION', responseBackend.data)
          deferred.resolve()
        } else {
          deferred.reject()
        }
      } else {
        deferred.reject()
      }
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  deleteSimulacion ({ commit }, simulacion) {
    try {
      commit('DELETE_SIMULACION', simulacion)
    } catch (error) {
      console.error(error)
    }
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
